<script>
import { mapGetters } from 'vuex'

export default {
  name: 'app-sidebar',
  computed: {
    ...mapGetters(['isLogged', 'currentUser', 'permissions', 'showSidebar', 'tenant'])
  },
  data() {
    return {
      activeIndex: '1',
      windowWidth: document.documentElement.clientWidth,
      menu: [
        {
          icon: 'fas fa-table-tennis',
          text: 'Painel Gripo',
          index: '1',
          children: [
            {
              icon: 'fas fa-suitcase',
              text: 'Clientes',
              index: '1',
              route: {
                name: 'client.index',
                index: 'client',
                params: {}
              }
            },
            {
              icon: 'fas fa-kaaba',
              text: 'Clubes',
              index: '2',
              route: {
                name: 'club.index',
                index: 'club',
                params: {}
              }
            },
            {
              icon: 'fas fa-money-bill-alt',
              text: 'Contas a Receber',
              index: '3',
              route: {
                name: 'entry.revenue.index',
                index: 'entry.revenue',
                params: {}
              }
            },
            {
              icon: 'far fa-money-bill-alt',
              text: 'Contas a Pagar',
              index: '4',
              route: {
                name: 'entry.expense.index',
                index: 'entry.expense',
                params: {}
              }
            },
            {
              icon: 'fas fa-users',
              text: 'Categorias',
              index: '6',
              route: {
                name: 'player_class.index',
                index: 'player_class',
                params: {}
              }
            },
            {
              icon: 'fas fa-user',
              text: 'Usuários',
              index: '7',
              route: {
                name: 'user.index',
                index: 'user',
                params: {}
              }
            },
            {
              icon: 'fas fa-file',
              text: 'Logs',
              index: '8',
              route: {
                name: 'log.index',
                index: 'log',
                params: {}
              }
            },
            {
              icon: 'far fa-bell',
              text: 'Notificações',
              index: '9',
              route: {
                name: 'notification.index',
                index: 'notification',
                params: {}
              }
            }
          ]
        }
      ]
    }
  },
  watch: {
    '$route.name': function (value) {
      if (value) {
        const routeData = this.menu[0].children.find(item => item.route.name === value)
        this.activeIndex = routeData?.index || '0'
      } else {
        this.activeIndex = '0'
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = document.documentElement.clientWidth
    },
    hasPermission(permission) {
      return this.permissions.some((item) => {
        return item.name === permission
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<template>
  <el-aside :width="showSidebar ? '220px' : '0px'" class="app-sidebar">
    <el-menu :default-openeds="['1']"
             :router="true">
      <template v-for="module in menu">
        <el-submenu :index="module.index" :key="module.index">
          <template slot="title" class="header">
            <span class="icon"><i :class="module.icon"></i></span>
            <span class="text" slot="title">{{ module.text }}</span>
          </template>
          <el-menu-item v-for="item in module.children"
                        :class="['menu-item', {'is-active': item.index === activeIndex}]"
                        :route="{name: item.route.name, params: item.route.params}"
                        :index="item.route.index"
                        :key="item.route.index">
            <span class="icon"><i :class="item.icon"></i></span>
            <span class="text">{{ item.text }}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </el-aside>
</template>
