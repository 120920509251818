import {routes as auth} from './auth'
import {routes as user} from './user'
import {routes as role} from './role'
import {routes as log} from './log'
import {routes as notification} from './notification'

export default [
    ...auth,
    ...user,
    ...role,
    ...log,
    ...notification
]
